import request from '@/utils/request'

// 获取列表
export function getListRequest(params) {
  return request({
    url: '/admin/xopinion/getList',
    method: 'get',
    params
  })
}

// 修改状态
export function setStatesRequest(params) {
  return request({
    url: '/admin/xopinion/setstate',
    method: 'get',
    params
  })
}

