<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
 
      <el-form-item>
        <el-input v-model="NickName" placeholder="关键字"></el-input>
      </el-form-item>
 
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="listData" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column label="图片" width="110" align="center">
          <template slot-scope="scope"> 
            <img :src="scope.row.imgs" alt="图片" class="table-row-image">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
 
          <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
           <el-table-column prop="remarks" label="备注" align="center"></el-table-column>
        <el-table-column prop="addDate" label="提交时间" align="center"></el-table-column>
        <el-table-column prop="states" label="处理状态" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-show="scope.row.state == 0">未处理</el-tag>
            <el-tag type="danger" v-show="scope.row.state == 1">已处理</el-tag>
          
          </template>
        </el-table-column>
        <el-table-column label="操作" width="213" align="center">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="setStates(scope.row.id)"
              v-if="scope.row.state == 0"
            >处理</el-button>
      
       
       
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background=""
        layout="prev, pager, next"
        :page-size="10"
        :current-page="pageIndex"
        :total="pageTotal"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getListRequest, setStatesRequest } from "@/api/opinion";
export default {
  name: "Userlist",
  data() {
    return {
      UserAccount: "",
      NickName: "",
      Phone: "",
      listData: [],
      pageIndex: 1,
      pageTotal: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getListRequest({
       
        name: this.NickName,
       
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        let data = res.data;
        this.listData = data.data;
        this.pageTotal = data.count;
      });
    },
 
    setStates(id) {
      this.$confirm("确认处理该意见吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 调用处理
          return setStatesRequest({ id });
        })
        .then(() => {
          this.$message({
            type: "success",
            message: `处理成功`
          });
          this.getList();
        });
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}
.table-row-image {
  width: 80px;
  height: auto;
}
</style>
